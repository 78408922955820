import React from "react";


const RouterErrorPage = () => {
    return ( 
       <div>
         <h1>Error Url</h1>
         <link exact to="/home">Home</link>
       </div>
     );
}
 
export default RouterErrorPage;