import { createSlice } from '@reduxjs/toolkit';

// ==============================|| REDUX - MAIN STORE ||============================== //

const User=createSlice({
    name:'User',
    initialState:{
       User:'',
       Regstatus:false,
       Paymentstatus:false,
       isLoading:false,
    },
    reducers:{
        GetUser:(state,action)=>{
        state.User=action.payload
        },
        GetRegStatus:(state,action)=>{
        state.Regstatus=action.payload
        },
        GetPaymentStatus:(state,action)=>{
        state.Paymentstatus=action.payload
        },
        Loading:(state,action)=>{
            state.isLoading=action.payload
        }
    }
});

export const UserActions = User.actions

export default User.reducer

