import "../../Assets/Styles/ParcelFlow_Profile.scss"
// import EditIcon from '@mui/icons-material/Edit';
import { Fragment, useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:'auto',
    bgcolor: 'background.paper',
    border: '2px solid transparent',
    boxShadow: 24,
    outline:'none',
    borderRadius:'5px',
    p:1,
  };



const ParcelflowProfile = () => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    return (
      <Fragment>
          <div className="Profile_Details">
            <div className="Container">
                <div className="Profile_Info">
                    <h2>My profile</h2>
                    <div className="Profile_Bottom">
                        <h4>General Information</h4>
                        {/* <div onClick={handleOpen} className="Edit_Btn">
                        <EditIcon/>
                        Edit
                        </div> */}
                    </div>
                </div>
                <div className="Card">
                    <div className="User_Name">WA</div>
                    <div className="Details">
                        <div className="Box">
                            <h3>Wasiu Afolabi</h3>
                            <h5>Tech</h5>
                        </div>
                        <div className="Box">
                            <div className="Gray_Text">Email Address</div>
                            <div className="Light_Text">wassuccess@ymail.com</div>
                        </div>
                        <div className="Box">
                            <div className="Gray_Text">Company</div>
                            <div className="Light_Text">Afo</div>
                        </div>
                        <div className="Box">
                            <div className="Gray_Text">Company Size</div>
                            <div className="Light_Text">0-49</div>
                        </div>
                        <div className="Box">
                            <div className="Gray_Text">Country</div>
                            <div className="Light_Text">Nigeria</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
           <div className="Edit_Modal">
            <div className="Form">
                <div className="Top">
                    <div className="Logo"></div>
                </div>
                <div className="Contents">
                <form>
            <div className="Box">
                <label htmlFor="name">
                    First Name*
                </label>
                <input placeholder="First Name" type="text" id="name"/>
            </div>
            <div className="Box">
                <label htmlFor="last">
                    Last Name*
                </label>
                <input placeholder="Last Name" type="text" id="last"/>
            </div>
            <div className="Box">
                <label htmlFor="email">
                    Email Address*
                </label>
                <input placeholder="Email Address" type="email" id="email"/>
            </div>
            <div className="Box">
                <label htmlFor="number">
                    Phone Number*
                </label>
                <input placeholder="Phone Number" type="tel" id="number"/>
            </div>
            <div className="Box">
                <label htmlFor="size">
                    State*
                </label>
                <select name="" id="size">
                    <option value="">Select State</option>
                    <option value="Abuja FCT">ABUJA FCT</option>
                    <option value="Abia">ABIA</option>
                    <option value="Adamawa">ADAMAWA</option>
                    <option value="Akwa Ibom">AKWA IBOM</option>
                    <option value="Anambra">ANAMBRA</option>
                    <option value="Bauchi">BAUCHI</option>
                    <option value="Bayelsa">BAYELSA</option>
                    <option value="Benue">BENUE</option>
                    <option value="Borno">BORNO</option>
                    <option value="Cross River">CROSS RIVER</option>
                    <option value="Delta">DELTA</option>
                    <option value="Ebonyi">EBONYI</option>
                    <option value="Edo">EDO</option>
                    <option value="Ekiti">EKITI</option>
                    <option value="Enugu">ENUGU</option>
                    <option value="Gombe">GOMBE</option>
                    <option value="Imo">IMO</option>
                    <option value="Jigawa">JIGAWA</option>
                    <option value="Kaduna">KADUNA</option>
                    <option value="Kano">KANO</option>
                    <option value="Katsina">KATSINA</option>
                    <option value="Kebbi">KEBBI</option>
                    <option value="Kogi">KOGI</option>
                    <option value="Kwara">KWARA</option>
                    <option value="Lagos">LAGOS</option>
                    <option value="Nassarawa">NASSARAWA</option>
                    <option value="Niger">NIGER</option>
                    <option value="Ogun">OGUN</option>
                    <option value="Ondo">ONDO</option>
                    <option value="Osun">OSUN</option>
                    <option value="Oyo">OYO</option>
                    <option value="Plateau">PLATEAU</option>
                    <option value="Rivers">RIVERS</option>
                    <option value="Sokoto">SOKOTO</option>
                    <option value="Taraba">TARABA</option>
                    <option value="Yobe">YOBE</option>
                    <option value="Zamfara">ZAMFARA</option>
                </select>
            </div>
            <div className="Box">
                <label htmlFor="size">
                    Have a Logistics Business?*
                </label>
                <select name="" id="size">
                    <option value="">Select Answer</option>
                    <option value="Yes">YES</option>
                    <option value="No">NO</option>
                </select>

            </div>
            <div className="Sign_Up">Update</div>  
            </form>
                </div>
            </div>
           </div>
          </Box>
        </Fade>
      </Modal>
      </Fragment>
    );
}
 
export default ParcelflowProfile;