import { Fragment } from 'react';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layouts/Layout';
import NavigationScroll from './Layouts/NavigationScroll';

function App() {
  return (
   <Fragment>
     <NavigationScroll>
    <Layout />
    </NavigationScroll>
    <ToastContainer />
   </Fragment>
  );
}

export default App;
