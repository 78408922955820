import img1 from "../../Assets/forward_img/sh.jpeg"
import img3 from "../../Assets/forward_img/ire.jpeg"
import img2 from "../../Assets/forward_img/thom.jpeg"
import img4 from "../../Assets/forward_img/tolutaiwo.jpeg"


export const Data = [
    {
        img: img1,
        name: 'Sheyi Akingbade',
        position: 'Founder & CEO',
        product: 'Parcelflow'
    },
    {
        img: img4,
        name: 'Tolu Taiwo',
        position: 'Growth Strategist',
        product: 'Parcelflow'
    },
    {
        img: img3,
        name: 'Pamilerin Eniolorunda',
        position: 'Communications & Publicist',
        product: 'Parcelflow'
    }
]


export const CardDetail = [
    {
        SubText: 'Keynote Sessions',
        haschild: true,
        Card: [
            {
                subtext: 'MARCH 25, 2023 AT 11:00 AM',
                title: "Introduction and Benefits of Logistics in Nigeria ",
                body: "Primarily for newcomers, attendees will get introduced  to running a logistics business with just an internet enabled device from any part of the country. Participants will also learn ways on how they can improve their sales and earning a daily revenue without having bikes, riders or any major partnership with any logistics company."
            },
            {
                subtext: 'MARCH 25, 2023 AT 4:00 PM',
                title: 'Understanding Parcelflow, the features and the purpose of Logistics Technology ',
                body: "Attendees will learn way how to utilize a cloud-based logistics system such as ParcelFlow to help streamline their businesses' supply chain process and ensure a more efficient and effective delivery experience. "
            },
        ]
    }
]

export const FQ = [
    {
        SubText: 'How much does it cost to attend this event?',
        content: "This event is a paid event and it cost 15,000 NGN to gain access!"
    },
    {
        SubText: 'Will the event content be available on-demand?',
        content: "No. The event will only hold once."
    },
    {
        SubText: 'Where will the event be held?',
        content: "Parcelflow Connect is a virtual event. "
    },
    {
        SubText: "I'd like to learn more about how Parcelflow can help my organization. Who can I talk to?",
        content: "We’d be delighted to help! Please enter your information here and we’ll get back to you as soon as possible."
    },
]