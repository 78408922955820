import {Fragment, useState} from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Fade} from '@mui/material';
import {CardDetail} from "./Profile_Details"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid transparent',
    boxShadow: 24,
    outline: 'none',
    borderRadius: '5px',
    p: 2,
};


const ParcelflowSchedule = () => {

    const [value, onChange] = useState(new Date());

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => setOpen(false);


    return (
        <Fragment>
            <div id='Sessions' className="Schedule_Section">
                <h5>SCHEDULE</h5>
                <h2>Explore our sessions</h2>
                <div className="Container">
                    {
                        CardDetail.map((item, index) =>
                            <>
                                <div key={index} className="SubText">{item.SubText}</div>
                                {
                                    item.Card.map((list, listIndex) =>
                                        <div key={listIndex} className="Card">
                                            <div className="Left">
                                                <div className="Subtext">{list.subtext}</div>
                                                <div className="Title">{list.title}</div>
                                                <div className="Body">
                                                    {list.body}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className="Calender_Modal">
                            <Calendar onChange={onChange} value={value}/>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </Fragment>
    );
}

export default ParcelflowSchedule;