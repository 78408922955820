import {useState} from "react";
import {FQ} from "./Profile_Details"


const ParcelFlowFQ = () => {

    const [SelectedIndex, setIndex] = useState()

    const handle = (index) => {
        setIndex(index)
    }

    const ClearIndex = () => {
        setIndex('')
    }


    return (
        <div id="FAQ" className="Frequently_Question">
            <div className="Container">
                <h2>Frequently asked questions</h2>
                {FQ.map((item, index) =>
                    <>
                        <div key={index} className="Card">
                            <div className="Title" >{item.SubText}</div>
                            {
                                SelectedIndex === index ?
                                    <svg onClick={ClearIndex} width="20" height="20" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" id="icon-keyboard-arrow-up" class="">
                                        <path
                                            d="M4.47126 17.5424C3.92519 18.1301 3.01786 18.1526 2.44468 17.5927C1.87151 17.0328 1.84953 16.1025 2.3956 15.5148C2.3956 15.5148 10.953 6.44563 10.9778 6.42089C11.5692 5.84131 12.5068 5.86299 13.072 6.46931L21.6027 15.5147C22.1498 16.1014 22.1293 17.0318 21.5571 17.5927C20.9848 18.1536 20.0774 18.1326 19.5304 17.5458L12.2029 9.90828C12.0927 9.79343 11.909 9.7934 11.7988 9.90823L4.47126 17.5424Z"
                                            fill="#878793"></path>
                                    </svg> :
                                    <svg onClick={() => handle(index)} width="20" height="20" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg" id="icon-keyboard-arrow-down"
                                         class="">
                                        <path
                                            d="M4.47126 6.45765C3.92519 5.86995 3.01786 5.84741 2.44468 6.40732C1.87151 6.96722 1.84953 7.89753 2.3956 8.48523C2.3956 8.48523 10.953 17.5544 10.9778 17.5791C11.5692 18.1587 12.5068 18.137 13.072 17.5307L21.6027 8.48532C22.1498 7.89855 22.1293 6.9682 21.5571 6.40732C20.9848 5.84643 20.0774 5.86741 19.5304 6.45417L12.2029 14.0917C12.0927 14.2066 11.909 14.2066 11.7988 14.0918L4.47126 6.45765Z"
                                            fill="#878793"></path>
                                    </svg>
                            }
                        </div>
                        <div className={`Drop_Down ${SelectedIndex === index ? 'active' : ''}`}>
                            <p>{item.content}</p>
                            <p>{item.content2}</p>
                            <p>{item.content3}</p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default ParcelFlowFQ;