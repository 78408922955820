import { Fragment } from "react";
import Route from "../Routes/Route";
import FooterPage from "../pages/Footer/Footer";
import HeaderPage from "../pages/Header/Header.js";
import { useLocation } from "react-router-dom";
const Layout = () => {
    const location = useLocation()
    const Locate = location.pathname
    return ( 
        <Fragment>
            {
                (Locate!=="/parcelflow-register" && Locate!=="/parcelflow-profile") 
                &&  <HeaderPage/>
            }
             <div>
            <Route/>
            </div>
            {
                 (Locate!=="/parcelflow-register" && Locate!=="/parcelflow-profile")  
                &&   <FooterPage/>
            }
        </Fragment>
     );
}
 
export default Layout;