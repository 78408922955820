import {useEffect, useState} from "react";
import "../../Assets/Styles/Parcel_Forward.scss"
import MakePaymentForm from "./MakePaymentForm";
import RegisterForm from "./RegisterForm";
import PaymentSuccessfulForm from "./PaymentSuccessfulForm";


const WebinarRegisterPage = () => {
    const [email, setEmail] = useState("");
    const [registrationStatus, setRegistrationStatus] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);

    return (
        <div className="Parcelflow_Form">
            <div className="Container">
                <div className="Left">
                    <div className="Info_Details">
                        <div className="Top">
                            <div className="Box">
                                <p>MAR</p>
                                <h2>25</h2>
                            </div>
                            <div className="Time">11:00</div>
                        </div>
                        <h3>Why Parcelflow Connect and what you stand to gain</h3>
                        <p>At Parcelflow Connect, you’ll learn how setup, run and manage a profitable logistics business without leaving the comfort of your seat. Attendees would be
                            shown how to use the Parcelflow service to conduct their business affairs all from their smartphones.
                        </p>
                        <p>At the end of the webinar, attendees would have all necessary information and tools they need to thrive in the world of
                            logistics thereby earning a comfortable income. All attendees would also be gifted a full 1 year subscription of the Parcelflow service
                            and souvenirs.</p>
                    </div>
                </div>
                <div className="Form">
                    <div className="Top">
                        <div className="Logo"></div>
                    </div>
                    <div className="Contents">
                        {/*<MakePaymentForm setRegistrationStatus={setRegistrationStatus} emailAddress={email} />*/}
                        {
                            paymentStatus === false ? (registrationStatus === false ?
                            <form>
                                <RegisterForm setPaymentStatus={setPaymentStatus} setRegistrationStatus={setRegistrationStatus} setEmailAddress={setEmail} />
                            </form> :
                                    <MakePaymentForm setPaymentStatus={setPaymentStatus} emailAddress={email} />
                                ) :
                                <PaymentSuccessfulForm  />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebinarRegisterPage;