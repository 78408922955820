import {usePaystackPayment} from 'react-paystack';
import axios from "axios";


const MakePaymentForm = ({emailAddress, setPaymentStatus}) => {
    const config = {
        reference: (new Date()).getTime().toString(),
        email: emailAddress,
        amount: 1500000,
        publicKey: 'pk_live_9a4d639092733cf1df856176995f732738f7b1e2',
    };

    // you can call this function anything
    const onSuccess = (reference) => {
        registerAttendant(reference.reference);
        setPaymentStatus(true);
    };

    // you can call this function anything
    const onClose = () => {
        console.log('closed')
    }

    const PaystackHookExample = () => {
        const initializePayment = usePaystackPayment(config);
        return (
            <div>
                <button className="payment-button" onClick={() => {
                    initializePayment(onSuccess, onClose)
                }}>MAKE PAYMENT
                </button>
            </div>
        );
    };

    const registerAttendant = (reference) => {
        const url = "https://parcelflow-web.mylogisticsapp.com/v1/actions/register-event.php";

        const data = {
            "email": emailAddress,
            "reference": reference
        }
        console.log(data);

        axios
            .post(url, data)
            .then(function (response) {
                console.log(response);

                if (response["data"]["success"] === false) {

                } else if (response["data"]["success"] === true) {

                }
            })
            .catch(function (error) {
                return error;
            });
    }

    return (
        <div>
            <h3 className="payment-header">Make payment</h3>
            <div className="Terms">
                {/*<p>*/}
                {/*    Click the below payment button to complete the last process.*/}
                {/*</p>*/}
            </div>
            <div className="Status"></div>
            <div className="Box">
                <label htmlFor="email">
                    Amount*
                </label>
                <input className="amount-field" disabled value="15000" type="number" id="email"/>
            </div>

            <PaystackHookExample/>
        </div>
    );
}

export default MakePaymentForm;