import {Fragment} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {UserActions} from "../../store/UserSlice";
import correctMark from "../../Assets/Parcelflow images/correct-mark.png";

const PaymentSuccessfulForm = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()


    const handle = () => {
        navigate('/', {replace: true})
    }

    return (
        <Fragment>
            <h3 className="payment-header">Congratulations!</h3>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:30, marginBottom:30}} className="Terms">
                <img style={{width: 105, height: 105}} src={correctMark}/>
            </div>
            <p className="payment-subtext">
                You registration was successful. You will contact via email and whatsapp for further details. <br/><br/>
                If you have any questions, please do not hesitate to send an email to <a href="mailto:info@parcellflow.io">info@parcellflow.io </a><br/><br/>
                You can also reach us on <a href="https://wa.me/+2349133344475">Whatsapp </a>
            </p>
            <div className="Btn_Wrap">
                <button onClick={handle} className="payment-button">BACK TO HOME</button>
            </div>
        </Fragment>
    );
}

export default PaymentSuccessfulForm;