import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist"
import { combineReducers } from "@reduxjs/toolkit";
import UserReducer from "./UserSlice"


const persistConfig={
    key:"root",
    version:1,
    storage
}

const reducer = combineReducers({
    Parcelflow:UserReducer,
});

const persistedReducer=persistReducer(persistConfig,reducer);

export const store = configureStore({
    reducer:persistedReducer
})