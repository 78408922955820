import {Fragment, useState} from "react";
import {Link} from "react-router-dom";
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from "react-redux";
import {CreateUser} from "../../store/UserAction";
import {UserActions} from "../../store/UserSlice";

const RegisterForm = ({setEmailAddress, setRegistrationStatus, setPaymentStatus}) => {
    const [Phone, setPhone] = useState('')
    const [phoneLength, setLength] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [SMS, setMessage] = useState()

    const {isLoading} = useSelector((state) => state.Parcelflow)
    const dispatch = useDispatch()

    console.log('number', Phone)


    const Numberhandle = (e) => {
        setPhone(e.target.value)
        const SliceNum = Phone.slice(0, 2)
        console.log('Slice number', SliceNum)
        if (Phone === '') {
            setMessage('Phone number is required')
            setPhoneError(true)
        }
        if (SliceNum !== '08' || SliceNum !== '09' || SliceNum !== '07') {
            setMessage('Phone number must start with either of the following. 09,08 and 07')
            setPhoneError(true)
        }
        if (SliceNum === '08' || SliceNum === '09' || SliceNum === '07') {
            setMessage('')
            setPhoneError(false)
        }
        if (Phone.length < 10 || Phone.length > 11) {
            setLength(true)
        }
        if (Phone.length === 10) {
            setLength(false)
        }
    }

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();


    const Nexthandle = () => {
        if (Phone === '') {
            setPhoneError(true)
            setMessage('Phone number is required')
        }
    }


    const onSubmit = (data) => {
        if ((phoneLength === false && phoneError === false) && data) {
            console.log(data)
            const detail = {
                "first_name": data.first_name,
                "last_name": data.last_name,
                "email_address": data.email,
                "phone_number": Phone,
                "state": data.state,
                "logistics_business": data.logistics_business
            }

            dispatch(UserActions.GetUser(detail))
            dispatch(CreateUser(detail, setPaymentStatus))

            setEmailAddress(data.email);
        }
    }


    return (
        <Fragment>
            <h3>Register for the event</h3>
            <div className="Box">
                <label htmlFor="name">
                    First Name*
                </label>
                <input
                    placeholder="First Name"
                    type="text"
                    id="name"
                    className={`${errors.first_name ? 'error' : null}`}
                    {...register('first_name', {required: true})}/>
                {errors.first_name && <span>First name is required.</span>}
            </div>
            <div className="Box">
                <label htmlFor="last">
                    Last Name*
                </label>
                <input
                    placeholder="Last Name"
                    type="text"
                    id="last"
                    className={`${errors.last_name ? 'error' : null}`}
                    {...register('last_name', {required: true})}/>
                {errors.last_name && <span>Last name is required.</span>}
            </div>
            <div className="Box">
                <label htmlFor="email">
                    Email Address*
                </label>
                <input
                    placeholder="Email Address"
                    type="email"
                    id="email"
                    className={`${errors.email ? 'error' : null}`}
                    {...register('email', {
                        required: 'Email is required',
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Please enter a valid email',
                        },
                    })}/>
                {errors.email?.message && <span>{errors.email?.message}</span>}
            </div>
            <div className="Box">
                <label htmlFor="number">
                    Phone Number*
                </label>
                <input
                    placeholder="Phone Number"
                    type="tel"
                    id="number"
                    value={Phone}
                    onChange={(e) => Numberhandle(e)}
                    className={`${phoneError ? 'error' : null}`}
                />
                {phoneError && <span>{SMS}</span>}
                {phoneLength && <span>Enter a valid phone number</span>}
            </div>
            <div className="Box">
                <label htmlFor="size">
                    State*
                </label>
                <select
                    name=""
                    id="size"
                    className={`${errors.state ? 'error' : null}`}
                    {...register('state', {required: true})}
                >
                    <option value="">Select State</option>
                    <option value="Abuja FCT">ABUJA FCT</option>
                    <option value="Abia">ABIA</option>
                    <option value="Adamawa">ADAMAWA</option>
                    <option value="Akwa Ibom">AKWA IBOM</option>
                    <option value="Anambra">ANAMBRA</option>
                    <option value="Bauchi">BAUCHI</option>
                    <option value="Bayelsa">BAYELSA</option>
                    <option value="Benue">BENUE</option>
                    <option value="Borno">BORNO</option>
                    <option value="Cross River">CROSS RIVER</option>
                    <option value="Delta">DELTA</option>
                    <option value="Ebonyi">EBONYI</option>
                    <option value="Edo">EDO</option>
                    <option value="Ekiti">EKITI</option>
                    <option value="Enugu">ENUGU</option>
                    <option value="Gombe">GOMBE</option>
                    <option value="Imo">IMO</option>
                    <option value="Jigawa">JIGAWA</option>
                    <option value="Kaduna">KADUNA</option>
                    <option value="Kano">KANO</option>
                    <option value="Katsina">KATSINA</option>
                    <option value="Kebbi">KEBBI</option>
                    <option value="Kogi">KOGI</option>
                    <option value="Kwara">KWARA</option>
                    <option value="Lagos">LAGOS</option>
                    <option value="Nassarawa">NASSARAWA</option>
                    <option value="Niger">NIGER</option>
                    <option value="Ogun">OGUN</option>
                    <option value="Ondo">ONDO</option>
                    <option value="Osun">OSUN</option>
                    <option value="Oyo">OYO</option>
                    <option value="Plateau">PLATEAU</option>
                    <option value="Rivers">RIVERS</option>
                    <option value="Sokoto">SOKOTO</option>
                    <option value="Taraba">TARABA</option>
                    <option value="Yobe">YOBE</option>
                    <option value="Zamfara">ZAMFARA</option>
                </select>
                {errors.state && <span>State is required.</span>}
            </div>
            <div className="Box">
                <label htmlFor="size">
                    Have a Logistics Business?*
                </label>
                <select
                    name=""
                    id="size"
                    className={`${errors.logistics_business ? 'error' : null}`}
                    {...register('logistics_business', {required: true})}
                >
                    <option value="">Select Answer</option>
                    <option value="Yes">YES</option>
                    <option value="No">NO</option>
                </select>
                {errors.logistics_business && <span>Logistics Business is required.</span>}
            </div>
            <div className="Terms">
                <p>
                    By completing and submitting the form below, you will receive information, tips, and promotions from
                    Parcelflow. To learn more about how Parcelflow uses your information, see our <Link>Privacy
                    Statement</Link>.
                </p>
                <p>
                    By joining the Parcelflow event, I accept Parcelflow's <Link>Event Terms and
                    Conditions</Link> and <Link>Event
                    Code of Conduct</Link>.
                </p>
            </div>
            <div
                className="Sign_Up"
                onClick={Phone === '' ? Nexthandle : handleSubmit(onSubmit)}
            >
                {
                    isLoading === true ? <span className="spinner-border spinner-grow-sm"/> : 'Next'
                }
            </div>
        </Fragment>
    );
}

export default RegisterForm;