import React, {Fragment, useEffect, useState} from 'react';
import Logo1 from "../../../Assets/Image/parcelflow-transparent-logo.png"
import {Link} from 'react-router-dom';
import "../../../Assets/Styles/nav_bar.scss";
import Btn from "../../../Components/Button.js";
import hamburger from "../../../Assets/Svg/hamburger.svg"
import close from "../../../Assets/Svg/close.svg"
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';







export default function ButtonAppBar() {
    const [SideBarToggle, setSideBarToggle] = useState(false);
   

    const handle = () => {
        setSideBarToggle(pre => !pre)
    };
  

    useEffect(() => {
        if ( window.innerWidth > 768) {
            setSideBarToggle(false)
        }
    }, []);



    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <Fragment>
            <div className="navbar_fill">
                <div className='Appbar'>
                    <div className='toolbar-left-content'>
                        <a href="/">
                            <img style={{height: "35px"}} src={Logo1} alt='Logo'/>
                        </a>
                        <ul className="App-header">
                            <li>
                            <a href="#Speakers">Speakers</a>
                            </li>
                            <li ><a href="#Sessions">Sessions</a></li>
                            <li ><a href="#FAQ">FAQ</a></li>
                        </ul>
                    </div>
                    <div className='toolbar-right-content'>
                        <Link to="parcelflow-register"><Btn name="Registration"/></Link>
                        <img onClick={handle} className='img img_hamburger' alt='search' src={hamburger}/>
                    </div>
                </div>
            </div>
            <div className={`mobile ${(SideBarToggle ? 'on-active' : 'not-active')}`}>
                <div className='Container'>
                    <div className='Wrapper_Relative'>
                    <div className='Top_Section'>
                        <img className='close' onClick={handle} src={close} alt="avatar"/>
                    </div>
                    <div className='List_of_Link'>
                        <ul>
                            <li>
                            <a onClick={handle} href="#Speakers">Speakers</a>
                            </li>
                            <li ><a onClick={handle} href="#Sessions">Sessions</a></li>
                            <li ><a onClick={handle} href="#FAQ">FAQ</a></li>
                        </ul>
                        <div className='btn_box'>
                        <Link onClick={handle} to="parcelflow-register"><Btn name="Registration"/></Link>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
            elevation: 0,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                },
                '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                },
            },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem component={Link} to="/parcelflow-profile" onClick={handleClose}>
            <Avatar sx={{ width: 32, height: 32 }}>P</Avatar> Profile
            </MenuItem>
            <MenuItem onClick={handleClose}>
            <ListItemIcon>
                <Logout fontSize="small" />
            </ListItemIcon>
            Logout
            </MenuItem>
        </Menu>
        </Fragment>

    );
}