import Button from "../../Components/Button"
import "../../Assets/Styles/Parcel_Forward.scss"
import {Fragment} from "react";
import ParcelflowJoinSection from "./JoinSection";
import ParcelflowSchedule from "./Schedule_Section";
import ParcelFlowFQ from "./Frequestly_FQ";
import {Link} from "react-router-dom";


const
    HomePage = () => {
        return (
            <Fragment>
                <div className="Parcelflow_Forward">
                    <div className="Container">
                        <div className="Left"></div>
                        <div className="Right">
                            <p>STARTS ON MARCH 25, 2023 AT 11:00 AM </p>
                            <h3>Running a <span style={{color: "#0b613a"}}>PROFITABLE</span> logistics business</h3>
                            <Link to="/parcelflow-register"> <Button name="Register Now"/></Link>
                        </div>
                    </div>
                </div>
                <ParcelflowJoinSection/>
                <ParcelflowSchedule/>
                <ParcelFlowFQ/>
            </Fragment>
        );
    }

export default HomePage;