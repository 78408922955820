import React from "react";
import {
    Routes,
    Route,
    // Navigate
} from 'react-router-dom';
import RouterErrorPage from "../pages/404Page/Error";
import ParcelForward from "../pages/ParcelflowForward/HomePage";
import ParcelflowProfilePage from "../pages/ParcelflowProfile/Profile";
import ParcelFormPage from "../pages/ParcelflowForwardForm/ParcelflowForm"



const Routers = () => {
    return (
        <Routes>
             <Route exact path="/" element={<ParcelForward/>}/>
            <Route exact path="/parcelflow-register" element={<ParcelFormPage/>}/>
            <Route exact path="/parcelflow-profile" element={<ParcelflowProfilePage/>}/>
            <Route exact path="**" element={<RouterErrorPage/>}/>
        </Routes>
    );
}

export default Routers;