import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '../../Components/Button';
import { Data } from "./Profile_Details";
import { Fragment, useState } from 'react';
import { Fade } from '@mui/material';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid transparent',
    boxShadow: 24,
    outline:'none',
    borderRadius:'5px',
    p:2,
  };



const ParcelflowJoinSection = () => {
    const [Profile,setProfile]=useState('')

    const [ ModalShow, setModalShow]=useState(false)

    const [open, setOpen] = useState(false);
    const handleOpen = (item) =>{
        setProfile(item)
        setOpen(true)
    };
    const handleClose = () => setOpen(false);



    return (
      <Fragment>
          <div className="Parcelflow_Join_Section">
            <div className="Join">
                <div className="Container">
                <div className="Left">
                    <h5>Why Join?</h5>
                    <p>At Parcelflow Connect, you’ll learn how setup, run and manage a profitable logistics business without leaving the comfort of your seat. Attendees would be
                        shown how to use the Parcelflow service to conduct their business affairs all from their smartphones.
                    </p>
                    <p>
                        At the end of the webinar, attendees would have all necessary information and tools they need to thrive in the
                        world of logistics thereby earning a comfortable income. All attendees would also be gifted a full 1 year subscription
                        of the Parcelflow service and souvenirs.
                    </p>
                </div>
                <div className="Avatar"></div>
                </div>
            </div>
            <div id='Speakers' className="Speakers_Section">
                  <h2 >Speakers</h2>
                  <p>Experts leading the charge in digital transformation</p>
                <div className="Speakers_Wrapper">
                   {
                    Data.map((item,index)=>(
                        <div key={index}  className="Profile">
                        <img src={item.img} alt={item.name} />
                        <div className="Details">
                            <div className="Name">{item.name}</div>
                            <div className="Product">{item.product}</div>
                            <p>{item.position}</p>
                        </div>
                    </div>
                    ))
                   }
                </div>
            </div>
        </div>
          <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="Profile_Modal">
                <div className="Close_Div">
                <svg onClick={handleClose}  width="14" height="14" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" role="graphics-symbol" aria-labelledby="x-icon-title-id" aria-hidden="false" fill="" ><title id="x-icon-title-id">x icon</title><path d="M9.0001 8.0626L13.4501 3.6126C13.7501 3.3126 13.7501 2.8626 13.4501 2.5626C13.1501 2.2626 12.7001 2.2626 12.4001 2.5626L7.9501 7.0126L3.5001 2.5126C3.2001 2.2126 2.7501 2.2126 2.4501 2.5126C2.1501 2.8126 2.1501 3.2626 2.4501 3.5626L6.9001 8.0126L2.4501 12.4626C2.1501 12.7626 2.1501 13.2126 2.4501 13.5126C2.6001 13.6626 2.8001 13.7126 3.0001 13.7126C3.2001 13.7126 3.4001 13.6626 3.5501 13.5126L8.0001 9.0626L12.4501 13.5126C12.6001 13.6626 12.8001 13.7126 13.0001 13.7126C13.2001 13.7126 13.4001 13.6626 13.5501 13.5126C13.8501 13.2126 13.8501 12.7626 13.5501 12.4626L9.0001 8.0626Z"></path></svg>
                </div>
                <img src={Profile.img} alt="" />
                <div className="Right">
                 <div className="Title">
                 <div className="Name">
                    {Profile.name}
                 </div>
                 <Button name="Speaker" />
                </div>
                <div className="Product">
                    {Profile.product}
                </div>
                <p>{Profile.position}</p>
                <div className={`Detail ${ ModalShow===true ? 'active': null}`}>
                    {Profile.name}
                “go-to” problem-solver and creative thinker. With over 10 years of experience in project management, Julia is known for producing high quality, mobile responsive microsites for brand partnerships and overseeing cross-functional teams to design custom promotions. Julia is an ardent fan of live music and considers X, Rancid, and The Muffs among her favorite bands.
                </div>
               {
                ModalShow===false && <span  onClick={()=>setModalShow(true)} ><Button name="Show More" /></span>
               }
                 </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </Fragment>
    );
}
 
export default ParcelflowJoinSection;