import axios from 'axios'
import { UserActions } from './UserSlice'
import { toast } from 'react-toastify';



export const CreateUser = (Data, setPaymentStatus) => {
  return async (dispatch) => {
    dispatch(UserActions.Loading(true))
    console.log('user', Data)
    const create = async () => {
      let data

      const res = await axios.post("https://parcelflow-web.mylogisticsapp.com/v1/actions/attend-event.php", Data) // returns promise
      data = res.data
      return data
    }
    try {
      const userState = await create()
      console.log('res user', JSON.stringify(userState))
      if (userState.success === false) {
        toast.error(userState.message)
      }
      if (userState.success === true) {
        toast.success(userState.data)
        setPaymentStatus(true)
        dispatch(UserActions.GetRegStatus(true))
      }
      dispatch(UserActions.Loading(false))
    } catch (error) {
      console.log('create user' + error.message)
      toast.error('Register error' + error.message)
      dispatch(UserActions.Loading(false))
    }
  }
}

export const PayBill = (Data) => {
  return async (dispatch) => {
    dispatch(UserActions.Loading(true))
    console.log('payment', Data)
    // const makePayment = async () => {
    //   let data

    //   const res = await axios.post("https://parcelflow-web.mylogisticsapp.com/v1/actions/register-event.php", Data) // returns promise
    //   data = res.data
    //   return data
    // }
    // try {
    //   const Paystate = await makePayment()
    //   console.log('res payment', JSON.stringify(Paystate))
    //   if (Paystate.success === false) {
    //     toast.error(Paystate.message)
    //   }
    //   if (Paystate.success === true) {
    //     toast.success(Paystate.data)
    //     dispatch(UserActions.GetPaymentStatus(true))
    //   }
    //   dispatch(UserActions.Loading(false))
    // } catch (error) {
    //   console.log('Payment error' + error.message)
    //   toast.error('Payment error' + error.message)
    //   dispatch(UserActions.Loading(false))
    // }
  }
}


